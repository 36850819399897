import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css';
import HomeHeaderPresenter from '../presenter/HomeHeaderPresenter';
import { ContainerWrap, ContentWrap, WifiWrap, UserWrap, SettingContentWrap, SettingContentWrap2, EctWrap, LikuVersion } from '../style/ContainerStyled'
import { HeaderWrap } from '../style/header/HeaderStyled'
import RootStore from '../../store';
import { observer } from 'mobx-react';
import LikuConnectModalPresenter from '../presenter/modal/connect/LikuConnectModalPresenter';
import SuccessFailureModalPresenter from '../presenter/modal/SuccessFailureModalPresenter';
import WaitModalContainer from '../container/modal/WaitModalContainer';
import HomeWiFiAndControllPresenter from '../presenter/home/HomeWiFiAndControllPresenter'
import HomeUserPresenter from '../presenter/home/HomeUserPresenter';
import HomeLikuWiFiSettingModalPresenter from '../presenter/modal/connect/HomeLikuWiFiSettingModalPresenter';
import LikuSettingBoxPresenter from '../presenter/home/LikuSettingBoxPresenter';
import { LkiuSettingIconGear, SettingIConWrap } from '../style/home/LikuSettingBoxPresenter';
import HomeLikuStateMemoModalPresenter from '../presenter/home/modal/HomeLikuStateMemoModalPresenter';
import HomeLikuUserModalPresenter from '../presenter/home/modal/HomeLikuUserModalPresenter';
import HomeLikuUserRecognizePresenter from '../presenter/home/modal/HomeLikuUserRecognizePresenter';
import DeleteModalPresenter from '../presenter/modal/DeleteModalPresenter';
import HomeLikuUserCheckModalPresenter from '../presenter/home/modal/HomeLikuUserCheckModalPresenter';
import DeleteLikuModalPresenter from '../presenter/modal/DeleteLikuModalPresenter';
import LikuRestartModalPresenter from '../presenter/modal/LikuRestartModalPresenter';
import HomeMenuBoxPresenter from '../presenter/home/HomeMenuBoxPresenter';
import LikuLangPresneter from './LikuLangPresneter';
import HomeWifiLangPresenter from '../presenter/home/HomeWifiLangPresenter';

import LikuVersionModalContainer from '../container/modal/LikuVersionModalContainer';
import {Mqtt} from "../../store/domainstore/mqtt";
import {MainUpdateModalUiStore} from "../../store/uistore/MainUpdateModalUiStore";
import MainUpdateModalContainer from "../container/main/modal/MainUpdateModalContainer";
import {MusicalModeModalUiStore} from "../../store/uistore/modal/MusicalModeModalUiStore";
import MusicalModeModalContainer from "../container/modal/MusicalModeModalContainer";

const HomePage = observer((props) => {
	const { LikuUiStore, ModalUiStore, DeleteModalUiStore, DrawerStore, LikuConnectModalUiStore, SuccessFailureModalUiStore, WiFiSettingModalUiStore } = RootStore();
  const [speakingText, setSpeakingText] = useState('');
  const [speakingIndex, setSpeakingIndex] = useState(null);
	const [state, setState] = useState(null);
	const visible = () => setState(true);
	const hidden = () => setState(false);

	const [versionModal, setVersionModal] = useState(false);

	useEffect(() => {
    	console.log('isRefreshingPost', LikuUiStore.isRefreshingPost);
	}, [LikuUiStore.isRefreshingPost]);

	useEffect(() => {
		console.log('useEffect thisLiku');
		if (LikuUiStore.thisLiku === null) Mqtt.mqttClose();
	}, [LikuUiStore.thisLiku]);

	return (
		<>
			<ContainerWrap>
				<HeaderWrap>
					<HomeHeaderPresenter
						page='HomePage'
						history={props.history}
						volume='volume'
						title={(LikuUiStore.thisLiku == null || LikuUiStore.thisLiku == undefined )? '' : LikuUiStore.thisLiku.name}
						subTitle={(LikuUiStore.thisLiku == null || LikuUiStore.thisLiku == undefined ) ? '' : LikuUiStore.thisLiku.wifi.ip}
						data={(LikuUiStore.thisLiku.wifi.signal === null ? '' : LikuUiStore.thisLiku.wifi.signal)}
					/>
				</HeaderWrap>
				<ContentWrap>
					<WifiWrap>
						{/*<HomeWifiLangPresenter*/}
						{/*	data={LikuUiStore.thisLiku !== null && LikuUiStore.thisLiku.wifi}*/}
						{/*	button='WiFi 변경 >'*/}
						{/*	icon='wifi'*/}
						{/*	buttonFunction={ModalUiStore.setOpen.bind(ModalUiStore,'wifiListModal',)}*/}
						{/*/>*/}
						<HomeWiFiAndControllPresenter
							data={LikuUiStore.thisLiku !== null && LikuUiStore.thisLiku.wifi}
							button='WiFi 변경 >'
							icon='wifi'
							buttonFunction={ModalUiStore.setOpen.bind(ModalUiStore,'wifiListModal',)}
						/>
						{ (LikuUiStore.thisLiku.wifi.state !== 'SCANNING' && LikuUiStore.thisLiku.wifi.state !== undefined) &&
							<LikuLangPresneter
								history={props.history}
							/>
							}
						{/* { (LikuUiStore.thisLiku.wifi.state !== 'SCANNING') &&
							<HomeMenuBoxPresenter
								history={props.history}
								button="표현 만들기 >"
								title="리쿠 표현 만들기"
								icon='express'
							/>
							}  */}
							{ (LikuUiStore.thisLiku.wifi.state !== 'SCANNING' && LikuUiStore.thisLiku.wifi.state !== undefined) &&
								<HomeWiFiAndControllPresenter
									history={props.history}
									button="컨트롤 하기 >"
									data="원격 시스템"
									icon='control'
								/>
							}

						{/* { (LikuUiStore.thisLiku.wifi.state !== 'SCANNING') &&
							<HomeMenuBoxPresenter
								history={props.history}
								button="접속 하기 >"
								title="리쿠에 접속하기"
								icon='login'
							/>
							}  */}
					</WifiWrap>
					 {/*{ (LikuUiStore.thisLiku.wifi.state !== 'SCANNING') &&*/}
						{/*<>*/}
						{/*	<EctWrap>*/}
						{/*		<HomeMenuBoxPresenter*/}
						{/*			history={props.history}*/}
						{/*			button="접속 하기 >"*/}
						{/*			title="리쿠에 접속하기"*/}
						{/*			icon='login'*/}
						{/*		/>*/}
						{/*		<HomeMenuBoxPresenter*/}
						{/*			history={props.history}*/}
						{/*			button="표현 만들기 >"*/}
						{/*			title="리쿠 표현 만들기"*/}
						{/*			icon='express'*/}
						{/*		/>*/}
						{/*	</EctWrap>*/}
						{/*</>*/}
						{/*}*/}
					 {/* UserWrpa -> 사용자 등록 */}
					 {/* { (LikuUiStore.thisLiku.wifi.state !== 'SCANNING') &&
						<>
							<UserWrap>
								<HomeUserPresenter
									speakingIndex={speakingIndex}
									setSpeakingIndex={setSpeakingIndex}
									choseUserFunction={null}
								/>
							</UserWrap>
						</>
					} */}
					<SettingContentWrap>
						<LikuVersion onClick={() => setVersionModal(true)}>
							버전 체크!
						</LikuVersion>
						<SettingContentWrap2>
							<LikuSettingBoxPresenter id='restart' title='리쿠 재시작' size='true' />
							<LikuSettingBoxPresenter id='memo' title='리쿠 상태 등록' size='true' />
							<LikuSettingBoxPresenter id='del' title='리쿠 리스트에서 해당 리쿠 삭제' size='false' />
						</SettingContentWrap2>
						<SettingIConWrap>
							<LkiuSettingIconGear onClick={() => DrawerStore.setLikuDeleteVisble()} />
						</SettingIConWrap>
					</SettingContentWrap>
				</ContentWrap>
			</ContainerWrap>
			{versionModal && <LikuVersionModalContainer setModal={setVersionModal.bind(this)}/>}
			{MainUpdateModalUiStore.visible.info && <MainUpdateModalContainer />}
			<LikuConnectModalPresenter
				open={ModalUiStore.open.likuConnectModal}
				setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
				likuConnectFunction={LikuConnectModalUiStore.likuConnectFunction.bind(
					LikuConnectModalUiStore)}
			/>
			<SuccessFailureModalPresenter
					open={ModalUiStore.open.successFailureModal}
					setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
					result={SuccessFailureModalUiStore.result}
					modal={SuccessFailureModalUiStore.modal}
				/>
			<WaitModalContainer />
			<HomeLikuWiFiSettingModalPresenter
					open={ModalUiStore.open.wifiSettingModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
					resetWiFi={WiFiSettingModalUiStore.resetWifi.bind(
						WiFiSettingModalUiStore,
					)}
					setWiFiFunction={WiFiSettingModalUiStore.setWiFi.bind(
						WiFiSettingModalUiStore,
					)}
					IsDisabled={WiFiSettingModalUiStore.IsDisabled}
				/>
				<HomeLikuStateMemoModalPresenter
					open={ModalUiStore.open.likuStateModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<HomeLikuUserModalPresenter
					open={ModalUiStore.open.userEnrollmentModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<HomeLikuUserCheckModalPresenter
					open={ModalUiStore.open.userCheckModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<HomeLikuUserRecognizePresenter
					open={ModalUiStore.open.userRecognizeModal}
					setOepn={ModalUiStore.setOpen.bind(ModalUiStore)}
				/>
				<DeleteModalPresenter
          open={ModalUiStore.open.deleteModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          item={DeleteModalUiStore.item}
          setDeleteFunction={DeleteModalUiStore.setDeleteFunction.bind(
            DeleteModalUiStore,
          )}
        />
				<DeleteLikuModalPresenter
          open={ModalUiStore.open.deleteLikuModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          item={DeleteModalUiStore.item}
					history={props.history}
        />
				<LikuRestartModalPresenter
          open={ModalUiStore.open.restartLikuModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
          item={DeleteModalUiStore.item}
					history={props.history}
        />
			{MusicalModeModalUiStore.visible && <MusicalModeModalContainer isBack={false} />}
		</>
	);
});

export default HomePage;
