import React, { useState } from 'react'
import RootStore from '../../../../store';
import { observer } from 'mobx-react';
import { Close, CloseBtn, CloseBtnTop, ModalWrapper, RefreshButton, RefreshIcon, SearchIcon, TitleWrap } from '../../../style/modal/connect/LikuConnectModalPresenter';
import { ModalSubText, ModalTitle, ModalTitleWrap, ModalWifiTitle, TitleIconWrap, WifiLists, WifiListTitle, WifiListWrap, WifiName } from '../../../style/modal/wifi/HomeLikuWifiModalPresenter';
import Loader from 'react-loader-spinner';
import { requestPermission } from '../../../../service/RequestPermission';

const HomeLikuWifiModalPresenter = observer(({open, setOpen}) => {
  const { LikuWebPostStore, WiFiSettingModalUiStore, ModalUiStore } = RootStore();
  const [click, setClick] = useState(false);
  const onChangeTrue = () => setClick(true);
  const onChangeFalse = () => setClick(false);

  return (
    <ModalWrapper
      visible={open}
  >
      <ModalTitleWrap>
        <TitleIconWrap>
          <TitleWrap>
            <SearchIcon />
            <ModalWifiTitle>Wi-Fi를 찾았습니다.</ModalWifiTitle>
          </TitleWrap>
          <ModalSubText>어떤 Wi-Fi를 연결하시겠습니까?</ModalSubText>
          <CloseBtnTop  onClick={ async () => {
         await ModalUiStore.setOpen('wifiListModal')
         await LikuWebPostStore.resetWifiList();
          }} >
            <Close />
          </CloseBtnTop>
        </TitleIconWrap>
      { click === false ?
          <RefreshButton onClick={ async () => {
            await requestPermission({ type: "WiFi_getList" });
            await LikuWebPostStore.resetWifiList();
            await onChangeTrue();
            setTimeout(async() => {
              onChangeFalse();
            }, 3500);
        }}>
            새로 고침
            <RefreshIcon />
        </RefreshButton>
        :
        <RefreshButton on={click} >
            불러오는 중
          {/* <RefreshIcon on={click} /> */}
          <Loader
							type="ThreeDots"
							color="#00BFFF"
              style={{marginLeft: '.1rem'}}
							height={7}
							width={15} //3 secs
						/>
        </RefreshButton>}
      <WifiListWrap>
        <WifiListTitle>연결 가능한 Wi-Fi</WifiListTitle>
        <WifiLists>
          {LikuWebPostStore.wifiList.map((wifi,idx) => wifi.id && (
              <WifiName
                key={idx}
                onClick={()=>{
                WiFiSettingModalUiStore.setChoseWifi(wifi);
                WiFiSettingModalUiStore.modalChange();
              }}>
                  <span>{wifi.id}</span>
                  <span>{wifi.signal}</span>
              </WifiName>
            ))}
        </WifiLists>
      </WifiListWrap>
      </ModalTitleWrap>
  </ModalWrapper>
  )
});
export default HomeLikuWifiModalPresenter
