import React, {useState} from 'react'
import {
  RefreshButton,
  RefreshIcon,
  DrawerSubText,
  DrawerText,
  HeaderDrawer,
  HeaderDrawerButton,
  HeaderDrawerButtonImage,
  HeaderDrawerButtonHomeImage,
  HeaderDrawerControllImage,
  HeaderDrawerText,
  HeaderDrawerTitle,
  HeaderDrawerTitleBox,
  HomeBtnContent,
  RowWrap,
  StopButton,
  StopButtonText,
  PlusIcon,
  PlusIcons,
  HeaderIconWrap,
  HeaderSub,
  RowWrap2
} from '../style/header/HeaderStyled'
import liku_home from '../../resource/img/liku_home.png'
import home from '../../resource/img/home.png'
import list from '../../resource/img/list.png';
import controller from '../../resource/img/controller.png'
import { observer } from 'mobx-react';
import { DrawerCloseBtn, DrawerContent, DrawerFooter, DrawerFooterText, DrawerHeader, DrawerHeaderTitle, DrawerWrap, FlatListWrap, HeaderDrawerImage, LikuAddBox, LikuNumBox, LikuNumText, LikuNumTitle, LikuNumWrap } from '../style/header/HomeDrawerPresenter';

import { HiArrowNarrowLeft } from "react-icons/hi";
import RootStore from '../../store'
import { requestPermission } from '../../service/RequestPermission'
import { LikuVolumeWrap, LikuVolumeWrapMain } from '../style/control/LikuControlContaier'
import HomeVolumePresenter from './home/HomeVolumePresenter'
import HomeVolumeMainPresenter from './home/HomeVolumeMainPresenter'
import useLongPress from "./useLongPress";
import {LikuWebPostStore} from "../../store/uistore/LikuWebPostStore";
import Loader from "react-loader-spinner";

const HomeHeaderPresenter = observer(({ title, subTitle, history, volume, data }) => {
  const { LikuUiStore, ModalUiStore, LikuStore, DrawerStore } = RootStore();
  const [click, setClick] = useState(false);
  const onChangeTrue = () => setClick(true);
  const onChangeFalse = () => setClick(false);

  const onLongPress = (liku) => {
    // console.log('onLongPress');
    LikuUiStore.setDeleteLiku(liku);
    ModalUiStore.setOpen('deleteLikuModal');
  };
  const onClick = async (liku) => {
    await history.push('/');
    await LikuUiStore.setThisLiku(liku, history);
  };

  const { onTouchEnd, onTouchStart } = useLongPress(); // onMouseDown, onMouseLeave, onMouseUp,

  // const useLongPressEvent = useLongPress(onLongPress);

  // const defaultOptions = {
  //   delay: 500,
  // };

  return (
    <>
      <HeaderDrawer>
        <HeaderDrawerButton>
          {title === '리쿠 컨트롤러' ?
            <>
              <HomeBtnContent>
                <HeaderDrawerButtonHomeImage onClick={() => {history.push("/home")}} src={home} />
                홈으로
              </HomeBtnContent>
            </>
          :
            <HeaderIconWrap>
              <HeaderDrawerButtonImage $type='home' onClick={() => DrawerStore.setAni(false)} src={list} />
              <HeaderSub>
                리쿠 리스트
              </HeaderSub>
            </HeaderIconWrap>
          }
        </HeaderDrawerButton>
        <HeaderDrawerTitleBox>
          <RowWrap>
            {title === '리쿠 컨트롤러' && <HeaderDrawerControllImage src={controller} />}
            <HeaderDrawerTitle this={title}>{title}</HeaderDrawerTitle>
          </RowWrap>
          <RowWrap>
            <HeaderDrawerText>{subTitle}</HeaderDrawerText>
            {data && (
                <RowWrap2>
                  <HeaderDrawerText>{data}</HeaderDrawerText>
                  {click ? (
                      <RefreshButton on={click} >
                        불러오는 중
                        {/* <RefreshIcon on={click} /> */}
                        <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            style={{marginLeft: '.1rem'}}
                            height={7}
                            width={15} //3 secs
                        />
                      </RefreshButton>
                  ): (
                      <RefreshButton onClick={ async () => {
                        await LikuUiStore.updateLikuData();
                        await onChangeTrue();
                        setTimeout(async() => {
                          onChangeFalse();
                        }, 6000);
                      }}>
                      새로 고침
                      <RefreshIcon />
                    </RefreshButton>
                  )}
                </RowWrap2>
            )}
          </RowWrap>
          {volume === 'volume' && (LikuUiStore.thisLiku.wifi.state !== 'SCANNING') &&
            <>
              <LikuVolumeWrapMain>
                <HomeVolumeMainPresenter />
              </LikuVolumeWrapMain>
            </>
          }
        </HeaderDrawerTitleBox>
      </HeaderDrawer>
      <DrawerWrap this={DrawerStore.drawerVisible}>
        <DrawerContent >
          <DrawerHeader onClick={() => DrawerStore.setAni(true)} >
            <HeaderDrawerImage src={liku_home} />
            <DrawerHeaderTitle>LiKU list</DrawerHeaderTitle>
            <DrawerCloseBtn>
              <HiArrowNarrowLeft />
              닫기
            </DrawerCloseBtn>
          </DrawerHeader>
          <LikuNumWrap>
            <FlatListWrap>
              {LikuStore.likus.map(liku => (
                <LikuNumBox key={liku}
                  this={LikuUiStore.thisLiku === liku}
                  onTouchStart={(e) => onTouchStart(e, onLongPress.bind(this, liku))}
                  onTouchEnd={() => onTouchEnd(onClick.bind(this, liku))}
                >
                  <LikuNumTitle this={LikuUiStore.thisLiku === liku}>{liku.name}</LikuNumTitle>
                  <LikuNumText this={LikuUiStore.thisLiku === liku}>{liku.mode}</LikuNumText>
                  <LikuNumText this={LikuUiStore.thisLiku === liku}>{liku.wifi.id}</LikuNumText>
                </LikuNumBox>
              ))}
            </FlatListWrap>
              <LikuAddBox  variant="text" onClick={ async () => {
                await requestPermission({ type: "Liku_getList" });
                await ModalUiStore.setOpen('likuConnectModal');
              }}>
                <PlusIcons />
                <DrawerText>리쿠 추가하기</DrawerText>
                <DrawerSubText>새로운 리쿠가 있다면 추가하세요.</DrawerSubText>
              </LikuAddBox>
          </LikuNumWrap>
          <DrawerFooter>
            <DrawerFooterText>ver 1.0.13.1 (2023.09.18)</DrawerFooterText>
            <DrawerFooterText>Copyright ⓒ 2020-{new Date().getFullYear()} TOROOC Inc. 모든 권리 보유</DrawerFooterText>
          </DrawerFooter>
        </DrawerContent>
      </DrawerWrap>
    </>
  )
});

export default HomeHeaderPresenter;
