import {observable, runInAction} from 'mobx';

import {LikuStore} from '../domainstore/LikuStore';
import {getVolume, setVolume} from '../api/ControllApi';
import { BluetoothStore } from '../domainstore/BluetoothStore';
import { requestPermission } from '../../service/RequestPermission';
import { ModalUiStore } from './modal/ModalUiStore';
import { SuccessFailureModalUiStore } from './modal/SuccessFailureModalUiStore';
import { DrawerStore } from './DrawerStore';
import { Mqtt } from '../domainstore/mqtt';
import { ControlUiStore } from './ControlUiStore';
import { get_uuid } from '../../utils/uuid';
import { removeCookie, setCookie } from '../../utils/cookie';
import { LikuWebPostStore } from './LikuWebPostStore';
import {MainUpdateModalUiStore} from "./MainUpdateModalUiStore";

const LikuUiStore = observable({
  thisLiku: null,
  thisLIkuBatteryNum: 0,
  thisLikuBatteryIcon: 'battery-0',
  thisLikuState: '',
  thisLikuStateMemo: [],
  thisLikuLang : null,
  thisConversation: false,

  deleteLiku: null,

  volumeAppList: [0, 17, 33, 50, 67, 83, 100],
  volumeControlList: [0, 11, 22, 44, 66, 80, 100],
  volume: 0,

  isRefreshingPost: false,

  updateInterval: null,
  interval: false,

  history: null,

  setHistory(history) {
    this.history = history;
  },

  setThisLikuLang(data) {
    this.thisLikuLang = data;
    console.log('Liku 현재 언어', this.thisLikuLang);
  },

  setThisConversation(data) {
    this.thisConversation = data;
    console.log('현자 말하기 모드', this.thisConversation);
  },

  setThisLikuStateMemo(text) {
    this.thisLikuStateMemo = text;
    // console.log('thisLikuStateMemo', this.thisLikuStateMemo);
  },

  saveMemo() {
    // console.log('saveMemo', this.thisLikuStateMemo);
    let MemoData = JSON.stringify(this.thisLikuStateMemo);
    // console.log('MemoData', MemoData);
    setCookie('MemoData', MemoData);
  },

  getThisLikuSerial() {
    return this.thisLiku.name.replace('LIKU: ', '');
  },

  setIsRefreshingPost() {
    this.isRefreshingPost = !this.isRefreshingPost;
  },

  updateThisLiku(liku) {
    this.thisLiku = liku;
    console.log('updateThisLiku', this.thisLiku);
  },

  setDeleteLiku(liku) {
    this.deleteLiku = liku;
  },

  async setThisLiku(liku, history) {
    removeCookie('likuSerial');
    let likuSerial = liku.name;
    likuSerial = likuSerial.replace('LIKU: ', '');
    console.log(likuSerial);
    setCookie('likuSerial', likuSerial, {path: '/', maxAge: 72000000});
    if (
      this.thisLiku !== null &&
      this.thisLiku !== undefined &&
      this.thisLiku.id === liku.id
    ) {
      this.interval = false;
      await BluetoothStore.disconnectLiku();
      await this.updateLikuStop();
      runInAction(() => {
        this.thisLiku = null;
      });
    } else {
      this.setHistory(history);
      await ModalUiStore.setOpen('waitModal');
      await BluetoothStore.disconnectLiku();
      this.thisLiku = liku;
      requestPermission({ type: "Liku_connect_normal", data: liku});
    }
  },

  async updataData () {
    let updateData = await BluetoothStore.updateLiku(this.thisLiku, null);
    console.log('updataDataupdataData', updateData);
    if (updateData !== false) {
      get_uuid().then(async ()=>{
        // await Mqtt.mqttSubscribe();
        // await Mqtt.getVolumeTopic();
        // await Mqtt.getLikuStateTopic();
        // await Mqtt.getUserListTopic();
        await this.history.push("/home");
        await this.updateThisLiku(updateData);


        await SuccessFailureModalUiStore.setResult(true);
        await SuccessFailureModalUiStore.setModalType('BluetoothConnect');
        await ModalUiStore.setOpen('waitModal');
        await ModalUiStore.setOpen('successFailureModal');
        await DrawerStore.setAni(true);
        this.interval = true;
        this.updateLikuData();
        // await requestPermission({ type: "InternetConrim" });
        // console.log('InternetConrim')
      });

      // await SuccessFailureModalUiStore.setResult(true);
      // await SuccessFailureModalUiStore.setModalType('BluetoothConnect');
      // await ModalUiStore.setOpen('waitModal');
      // await ModalUiStore.setOpen('successFailureModal');
      // await DrawerStore.setAni(true);
      // this.interval = true;
      // this.updateLikuData();
    } else {
      this.thisLiku = null;
      console.log('====thisLiku====', this.thisLiku);
      await SuccessFailureModalUiStore.setResult(false);
      await SuccessFailureModalUiStore.setModalType('BluetoothConnect');
      await ModalUiStore.setOpen('waitModal');
      await ModalUiStore.setOpen('successFailureModal');
    }
  },

  updateLikuData() {
    console.log('updateLikuData');
    // this.updateInterval = setInterval(() => {
      requestPermission({type: 'Liku_getDeviceInfo'});
    // }, 3000)
  },

  controlUpdateLikuData() {
    // this.updateInterval = setInterval(() => {
      requestPermission({type: 'Liku_control_Info'});
      requestPermission({type: 'InternetConrim'});
    // }, 3000)
  },

  async updataDataData() {
    this.setIsRefreshingPost();
    // let modalVisible = await ModalUiStore.getModalVisibleTrue();
    if (this.interval && this.thisLiku !== null) {
      let updateData = false;
      if (this.interval && LikuWebPostStore.bluetoothDeviceInfo !== false && this.thisLiku !== null) {
        console.log('======= LikuWebPostStore.bluetoothDeviceInfo : ', LikuWebPostStore.bluetoothDeviceInfo);
        if (LikuWebPostStore.bluetoothDeviceInfo.wifi !== 'TIMEOUT') {
          console.log('!~!!!!!!!!!!!!!!!!! LikuWebPostStore.bluetoothDeviceInfo.wifi !== TIMEOUT')
          console.log('````````~~~~~:', this.thisLiku.wifi.state);
          // if (this.thisLiku.wifi.state === 'COMPLETED' || this.thisLiku.wifi.state === 'CONNECTED') {
          if (LikuWebPostStore.bluetoothDeviceInfo.wifi.state === 'COMPLETED' || LikuWebPostStore.bluetoothDeviceInfo.wifi.state === 'CONNECTED') {
            console.log('mqtt complete');
            await Mqtt.allSub();
            await Mqtt.getVolumeTopic();
            await Mqtt.getLikuStateTopic();
            // await Mqtt.getUserListTopic();
            await Mqtt.getLanguageState();
            await Mqtt.getConversation();
            await MainUpdateModalUiStore.updateCheck();
            await requestPermission({ type: "InternetConrim" });
            console.log('InternetConrim');
          }
          console.log('this.thisLiku.wifi.state !== COMPLETED');
          let updateDataLiku = await LikuStore.updateLiku(
            this.thisLiku,
            LikuWebPostStore.bluetoothDeviceInfo,
            null
          );
          await LikuStore.saveLiku();
          updateData = true;
          return updateDataLiku;
        } else {
          console.log('=============**** : ', LikuWebPostStore.bluetoothDeviceInfo.wifi);
          console.log('TIMEOUTTIMEOUTTIMEOUTTIMEOUTTIMEOUTTIMEOUTTIMEOUTTIMEOUT');
            this.interval = false;
            await this.updateLikuStop();
            runInAction(() => {
              this.thisLiku = null;
            });
            await this.history.push("/");
            await BluetoothStore.disconnectLiku();
            await SuccessFailureModalUiStore.setResult(false);
            await SuccessFailureModalUiStore.setModalType('likuDisconnect');
            await ModalUiStore.setOpen('successFailureModal');
        }
      }
      console.log('==+=+++++======', updateData);
      if (this.interval && updateData !== false) {
        await this.updateThisLiku(updateData);
      }
    }
    this.setIsRefreshingPost();
  },

  async updataControlData() {
    this.setIsRefreshingPost();
    // let modalVisible = await ModalUiStore.getModalVisibleTrue();
    if (this.interval && this.thisLiku !== null) {
      let updateData = false;
      if (this.interval && LikuWebPostStore.bluetoothDeviceInfo !== false && this.thisLiku !== null) {
        console.log('======= LikuWebPostStore.bluetoothDeviceInfo : ', LikuWebPostStore.bluetoothDeviceInfo);
        if (LikuWebPostStore.bluetoothDeviceInfo.wifi !== 'TIMEOUT') {
          console.log('!~!!!!!!!!!!!!!!!!! LikuWebPostStore.bluetoothDeviceInfo.wifi !== TIMEOUT')
          console.log('````````~~~~~:', this.thisLiku.wifi.state);
          if (this.thisLiku.wifi.state === 'COMPLETED' || this.thisLiku.wifi.state === 'CONNECTED') {
            console.log('mqtt complete');
            // await Mqtt.allSub();
            await Mqtt.getVolumeTopic();
            await Mqtt.getLikuStateTopic();
            // await Mqtt.getUserListTopic();
            // await Mqtt.getLanguageState();
            // await Mqtt.getConversation();
          }
          else if (LikuUiStore.thisLiku.wifi.state == 'SCANNING') {
            setTimeout(async() => {
              await SuccessFailureModalUiStore.setResult(false);
              await SuccessFailureModalUiStore.setModalType('wifiConnect');
              await ModalUiStore.setOpen('successFailureModal');
            }, 2000);
          }
          console.log('this.thisLiku.wifi.state !== COMPLETED');
          let updateDataLiku = await LikuStore.updateLiku(
            this.thisLiku,
            LikuWebPostStore.bluetoothDeviceInfo,
            null
          );
          await LikuStore.saveLiku();
          updateData = true;
          return updateDataLiku;
        } else {
          console.log('=============**** : ', LikuWebPostStore.bluetoothDeviceInfo.wifi);
            this.interval = false;
            await this.updateLikuStop();
            await this.history.push("/");
            runInAction(() => {
              this.thisLiku = null;
            });
            await BluetoothStore.disconnectLiku();
            await SuccessFailureModalUiStore.setResult(false);
            await SuccessFailureModalUiStore.setModalType('likuDisconnect');
            await ModalUiStore.setOpen('successFailureModal');
        }
      }
      console.log('==+=+++++======', updateData);
      if (this.interval && updateData !== false) {
        await this.updateThisLiku(updateData);
      }
    }
    this.setIsRefreshingPost();
  },

  updateLikuStop() {
    console.log('updateLikuStop')
    clearInterval(this.updateInterval);
    this.updateInterval = null;
    this.updateInterval = undefined;
    // console.log('updateLikuStop', this.updateInterval);
  },

  async preRestartLiku() {
    await ModalUiStore.setOpen('waitModal');
    requestPermission({ type: "Liku_Restart" , data: 'auto_start'});
  },
  async restartLiku() {
    setTimeout(() => {
      // console.log('restart');
      ModalUiStore.setIsCountDown();
    }, 2000);
  },

  async removeLiku() {
    requestPermission({ type: "Liku_disconnect" });
    if (this.thisLiku === null) await (this.thisLiku = this.deleteLiku);
    await LikuStore.removeLiku(this.thisLiku);
    // runInAction(() => {
    //   this.thisLiku = null;LikuUiStore.preRestartLiku();
    // });
  },

  async getVolume() {
    let v = await getVolume();
    console.log('getVolume: ', typeof v);
    console.log('getVolume: ', v);
    if (v !== undefined) {
      runInAction(() => {
        this.volume = v;
      });
      return v;
    } else {
      return false;
    }
  },

  setBatteryIcon(batteryNum) {
    let battery = parseInt(batteryNum);
    // console.log('batterybattery', typeof battery);
    if (
      battery !== '' &&
      battery !== 'NaN' &&
      // battery !== NaN &&
      battery !== undefined &&
      battery !== null &&
      !isNaN(battery)
    ) {
      this.thisLIkuBatteryNum = battery;
    } else {
      this.thisLIkuBatteryNum = 0;
    }
    // console.log('battery : ', this.thisLIkuBatteryNum);
    switch (String(parseInt(battery / 10))) {
      case '10':
        this.thisLikuBatteryIcon = 'battery-full';
        break;
      case '9':
      case '8':
      case '7':
        this.thisLikuBatteryIcon = 'battery-3';
        break;
      case '6':
      case '5':
      case '4':
        this.thisLikuBatteryIcon = 'battery-2';
        break;
      case '3':
      case '2':
      case '1':
        this.thisLikuBatteryIcon = 'battery-1';
        break;
      default:
        this.thisLikuBatteryIcon = 'battery-0';
    }
    // console.log(this.thisLikuBatteryIcon);
  },

  setThisLikuState(state) {
    this.thisLikuState = state;
    // ControlUiStore.setSpeakingStart(null, null);
    // console.log('this.likuState', this.thisLikuState);
  },
});

export {LikuUiStore};
