import { observable } from "mobx";
import { requestPermission } from "../../../service/RequestPermission";
import { BluetoothStore } from "../../domainstore/BluetoothStore";
import { LikuUiStore } from "../LikuUiStore";
import { LikuWebPostStore } from "../LikuWebPostStore";
import { ModalUiStore } from "./ModalUiStore";
import { SuccessFailureModalUiStore } from "./SuccessFailureModalUiStore";
import {Mqtt} from "../../domainstore/mqtt";
import {MainUpdateModalUiStore} from "../MainUpdateModalUiStore";

const WiFiSettingModalUiStore = observable({
  choseWiFi: {ssid: 'WiFi'},
  wifiPassword: '',
  wifiId: '',
  IsDisabled: false,

  setChoseWifi(wifi) {
    console.log('====wifi====', wifi);
    this.choseWiFi = {
      ssid: wifi.id,
      key: wifi.key,
      auth: wifi.auth,
      // auth: '802.1x',
    };
    console.log('this.choseWifi', this.choseWiFi.ssid);
  },

  resetWifi() {
    console.log('resetWifi');
    this.choseWiFi = {ssid: 'WiFi'};
    this.wifiPassword = '';
    this.wifiId = '';
    this.IsDisabled = false;
  },

  setWiFiId(id) {
    this.wifiId = id;
    this.IsDisabledWifiButton();
  },

  setWiFiPassword(password) {
    this.wifiPassword = password;
    this.IsDisabledWifiButton();
  },

  modalChange() {
    ModalUiStore.setOpen('wifiListModal');
    ModalUiStore.setOpen('wifiSettingModal');
  },


  hasErrors() {
    if (this.wifiPassword.length >= 8) {
      return false;
    } else {
      return true;
    }
  },

  IsDisabledWifiButton() {
    if (
      this.choseWiFi.auth === '802.1x' &&
      this.wifiId !== '' &&
      this.wifiPassword !== '' &&
      this.wifiPassword.length >= 8
    ) {
      this.IsDisabled = true;
      // console.log('IsDisabled 1');
    } else if (
      this.choseWiFi.key !== 'off' &&
      this.wifiPassword !== '' &&
      this.wifiPassword.length >= 8
    ) {
      this.IsDisabled = true;
      // console.log('IsDisabled 2');
    } else if (this.choseWiFi.key === 'off') {
      this.IsDisabled = true;
      // console.log('IsDisabled 3');
    } else {
      this.IsDisabled = false;
      // console.log('IsDisabled 4');
    }
  },

  async setWiFi(type) {
    SuccessFailureModalUiStore.setModalType('wifiConnect');
    ModalUiStore.setOpen('wifiSettingModal');
    ModalUiStore.setOpen('waitModal');
    this.choseWiFi.id = this.wifiId;
    this.choseWiFi.password = this.wifiPassword;
    requestPermission({ type: "WiFi_setWifi", data: this.choseWiFi});
    LikuWebPostStore.setWifiType(type);
  },

  async setWiFiInfo() {
    console.log('setWifiInfo')
    if (LikuWebPostStore.wifitype !== 'pass') {
      await LikuUiStore.updateLikuStop();
      // ModalUiStore.setOpen('waitModal');
      setTimeout(async () => {
        let r = false;
        if (
          !LikuWebPostStore.wifiInfo.state.includes('_changeFail') &&
          (LikuWebPostStore.wifiInfo.state === 'CONNECTED' ||
          LikuWebPostStore.wifiInfo.state === 'COMPLETED')) {
          r = true;
        }
        console.log(r)
        let newData = await BluetoothStore.updateLiku(
          LikuUiStore.thisLiku,
          this.choseWiFi,
        );
        console.log('newData', newData);
        await LikuUiStore.updateThisLiku(newData);
        // await LikuUiStore.updateLikuData();
        await SuccessFailureModalUiStore.setResult(r);
        ModalUiStore.setOpen('waitModal');
        ModalUiStore.setOpen('successFailureModal');
        await LikuUiStore.updateLikuData();
        this.resetWifi();


        // if (this.thisLiku.wifi.state === 'COMPLETED' || this.thisLiku.wifi.state === 'CONNECTED') {
        //   console.log('mqtt complete');
        //   // await Mqtt.allSub();
        //   await Mqtt.getVolumeTopic();
        //   await Mqtt.getLanguageState();
        //   await Mqtt.getConversation();
        //   await MainUpdateModalUiStore.updateCheck();
        // }
      }, 2000);
    } else {
      await SuccessFailureModalUiStore.setResult(true);
      ModalUiStore.setOpen('successFailureModal');
      this.resetWifi();
    }
  }

});

export {WiFiSettingModalUiStore};
