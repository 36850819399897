import { observable } from "mobx";
import mqtt from "mqtt";
import { getCookie, setCookie } from "../../utils/cookie";
import { LikuUiStore } from "../uistore/LikuUiStore";
import { ModalUiStore } from "../uistore/modal/ModalUiStore";
import { SuccessFailureModalUiStore } from "../uistore/modal/SuccessFailureModalUiStore";
import { VisualizationUiStore } from "../uistore/VisualizationUiStore";
import { LikuStore } from "./LikuStore";
import { UserStore } from "./UserStore";

const Mqtt = observable({
	client: '',

  volumeAppList: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  volumeControlList: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  likuVolume: 0,

	setLikuVolume(volume) {
		this.likuVolume = volume;
	},

	mqttConenct() {
		const url = 'ws://ec2-54-180-207-182.ap-northeast-2.compute.amazonaws.com:15675';
    const options = {
			keepalive: 30,
			protocolId: 'MQTT',
			protocolVersion: 4,
			clean: true,
			path:"/ws",
			reconnectPeriod: 1000,
			connectTimeout: 30 * 1000,
			will: {
					topic: 'WillMsg',
					payload: 'Connection Closed abnormally..!',
					qos: 0,
					retain: false
			},
			rejectUnauthorized: false
		}
		options.clientId = `mqttjs_${Math.random().toString(16).substr(2, 8)}`;
		options.username = 'liku';
		options.password = 'likuliku20';
		this.client = mqtt.connect(url, options);

		if(this.client) {
			this.client.on('error', (err) => {
					console.error('Connection error: ', err)
			})
			this.client.on('connect', () => {
					console.log('Client connected:')
			})
			this.client.on('disconnect', (err) => {
				console.error('Connection error: ', err)
			})
			this.client.on('close', () => {
				console.log('Client closed:');
			});
			this.client.on('reconnect', () => {
				console.log('Client reconnect:');
			});
			this.client.on('message', async (topic, message) => {

				console.log('onMessage topic', topic);
				console.log('asdfasdf', topic.indexOf('surroundings') !== -1);
				let message_obj= undefined;
				if (topic.indexOf('vision') === -1)
					message_obj = JSON.parse(message);
				if (topic.indexOf('volume') !== -1) {
						console.log(message_obj.volume);
						this.getVolume(message_obj.volume);
				} else if (topic.indexOf('motion-status') !== -1) {
					console.log('리쿠 상태', message_obj.status);
						setCookie('likuState', message_obj.status);
						let data;
						if (message_obj.status === 'sleeping') {
							data = '자는중';
						} else if (message_obj.status === 'active') {
							data = '일어남';
						} else {
							data = 'musical';
						}
						LikuUiStore.setThisLikuState(data);
				} else if (topic.indexOf('language') !== -1) {
						setCookie('likuLang', message_obj.language);
						let data;
						if (message_obj.language === 'ko') {
							data = 'ko';
						} else {
							data = 'en';
						}
						LikuUiStore.setThisLikuLang(data);
						console.log('responseresponsemessage.obj', message_obj.language);
				} else if (topic.indexOf('conversation') !== -1) {
						let data;
						if (message_obj.conversation) {
							data =  true;
						} else {
							data = false;
						}
					LikuUiStore.setThisConversation(data);
					console.log('responsereconversationconversationsponsemessage.obj', message_obj.conversation);
				}
				else if (topic.indexOf('user/reg/report') !== -1) {
					this.userTopic(message_obj.data);
					console.log('user/reg/report message_obj.data', message_obj.data);
				} else if (topic.indexOf('user/list') !== -1) {
						UserStore.setUserList(message_obj);
						console.log('UserStore.userList', UserStore.userList);
				} else if (topic.indexOf('user/reg/add') !== -1) {
					this.userTopic(message_obj.data);
				}
				else if (topic.indexOf('surroundings') !== -1) {
					let data;
					message_obj = JSON.parse(message);
					data = message_obj[0].PO;
					console.log('surroundings', message_obj);
					console.log('surroundings', message_obj[0].PO);
					UserStore.setUserRequest(data);
					VisualizationUiStore.drawingBox2(message_obj);
				}
				else if (topic.indexOf('vision') !== -1) {
					let image = URL.createObjectURL(
						new Blob([message], { type: 'image/png' } /* (1) */)
					);
					// console.log(image);
					VisualizationUiStore.setRgbData(image);
				}
			});
		}
	},

	mqttClose() {
		console.log('mqttClose');
		this.client.end();
	},

	mqttPublish(topic, payload) {
		if(this.client) {
			payload = JSON.stringify(payload);
			// topic = JSON.stringify(topic);
			console.log('mqttPublish payload', payload);
			console.log('mqttPublish topic', topic);
			this.client.publish(topic, payload, 1, error => {
				if(error) {
					console.log('Publish error: ', error);
				}
			});
		}
	},

	async allSub() {
		let likuSerial = await getCookie('uuid');
		const volumeTopic = `liku/${likuSerial}/volume`;
		const motionTopic = `liku/${likuSerial}/motion-status`;
		const languageTopic = `liku/${likuSerial}/language/response`;
		const conversationTopic = `liku/${likuSerial}/conversation/response`;
		const userReportTopic = `liku/${likuSerial}/user/reg/report`;
		const userlistTopic = `liku/${likuSerial}/user/list`;
		const visionImageTopic = `liku/${likuSerial}/vision/image/compressed`;
		const visionSurroundingsTopic = `liku/${likuSerial}/vision/surroundings`;

		await this.mqttSubscribe(volumeTopic);
		await this.mqttSubscribe(motionTopic);
		await this.mqttSubscribe(languageTopic);
		await this.mqttSubscribe(conversationTopic);
		await this.mqttSubscribe(userReportTopic);
		await this.mqttSubscribe(userlistTopic);
		await this.mqttSubscribe(visionImageTopic);
		await this.mqttSubscribe(visionSurroundingsTopic);

	},

	async mqttSubscribe(topic) {
		if (this.client) {
			console.log('subscriber')
			await this.client.subscribe(topic, 1, (error) => {
				if (error) {
					console.log('Subscribe to topics error', error)
					return
				}
			});
		}
	},

	getVolume(volume) {
		this.likuVolume = volume;
	},

	async setVolumeTopic(volume) {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/volume/set`;
		let message = {volume: volume.toString()};
		console.log('setVolumeTopic', topic, message);
		LikuUiStore.controlUpdateLikuData();
		this.mqttPublish(topic, message);
	},

	async getVolumeTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/volume/get`;
		let message = {volume: 'get'};
		// console.log('getVolumeTopic', topic, message);
		this.mqttPublish(topic, message);
	},

	async getLikuStateTopic() {
		let likuSerial = await getCookie('uuid');
    let topic = `liku/${likuSerial}/motion-status/get`;
    let message = {message: 'data'};
    this.mqttPublish(topic, message);
	},

	async setLikuSleepTopic() {
		LikuUiStore.controlUpdateLikuData();
    let likuState = await getCookie('likuState');
		let data;
    if (likuState === 'active') { //재우기
				let likuSerial = await getCookie('uuid');
        let topic = `liku/${likuSerial}/exp`;
        let message = {
                "exp" : [["YellowBlink", "Rest", "Sleep"]],
                "listen": false
            };
        this.mqttPublish(topic, message);
				data = '자는중';
    }
		// await LikuUiStore.setThisLikuState(data);
	},

	async setLikuWakeupTopic() {
		LikuUiStore.controlUpdateLikuData();
		// console.log('setLikuWakeupTopic');
    let likuState = await getCookie('likuState');
		let data;
    if (likuState !== 'active') { //깨우기
				let likuSerial = await getCookie('uuid');
				let topic = `liku/${likuSerial}/exp`;
				let message = {
								"exp" : [["WakeUp"]],
								"listen": false
						};
				this.mqttPublish(topic, message);
				data = '일어남';
    }
		// await LikuUiStore.setThisLikuState(data);
	},

	async setLikuStupChairTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/exp`;
		let message = {
				"exp" : [["SelfStandUp"]],
				"listen": false
				};
		this.mqttPublish(topic, message);
	},

	async setLikuStupChairTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/exp`;
		let message = {
			"exp" : [["SelfSitDown"]],
			"listen": false
				};
		this.mqttPublish(topic, message);
	},

	async setDanceTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/exp`;
		let message = {
			"exp" : [["Dance"]],
			"listen": false
				};
		this.mqttPublish(topic, message);
	},

	async setUserNameTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/user/reg/add`;
		let message = {name: UserStore.user, type: 'new'};
		this.mqttPublish(topic, message);
	},

	async setUserSuccessTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/user/reg/signal`;
		let message = 'start';
		this.mqttPublish(topic, message);
	},

	async setUserFailTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/user/reg/signal`;
		let message = 'retry';
		this.mqttPublish(topic, message);
	},

	async getUserListTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/user/list/get`;
		let message = 'userlist';
		this.mqttPublish(topic, message);
	},

	async setUserDeleteTopic(name) {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/user/reg/add`;
		let message = {name: name, type: 'del'};
		this.mqttPublish(topic, message);
	},

	async setUserRetryTopic(name) {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/user/reg/add`;
		let message = {name: name, type: 're'};
		this.mqttPublish(topic, message);
	},

	async getImageTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/vis/image/compressed/change`;
		let message = {vis_mode : true}
		this.mqttPublish(topic, message);
	},

	async setImageTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/vis/image/compressed/change`;
		let message = {vis_mode : false}
		this.mqttPublish(topic, message);
	},

	async stopTopic() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/user/stop`;
		let message = 'stop'
		this.mqttPublish(topic, message);
	},


	async userTopic(data) {
				if (data === 'regDone') {
					await SuccessFailureModalUiStore.setResult(true);
					await SuccessFailureModalUiStore.setModalType('enrollment');
					await ModalUiStore.setOpen('userRecognizeModal');
					await ModalUiStore.setOpen('successFailureModal');
					await UserStore.setResetUser();
					await LikuStore.updateMqttData();
				} else if (data === 'fail') {
					await this.setImageTopic();
					await ModalUiStore.setVisibleFalse();
					await SuccessFailureModalUiStore.setResult(false);
					await SuccessFailureModalUiStore.setModalType('enrollment');
					await ModalUiStore.setOpen('successFailureModal');
					await UserStore.setResetUser();
					await LikuStore.updateMqttData();
				} else if (data === 'delDone') {
					await this.getUserListTopic();
					await SuccessFailureModalUiStore.setResult(true);
					await SuccessFailureModalUiStore.setModalType('user');
					await ModalUiStore.setOpen('waitModal');
					await ModalUiStore.setOpen('successFailureModal');
					await LikuStore.updateMqttData();
				}
	},

	async setLanguageKo() {
		LikuUiStore.controlUpdateLikuData();
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/language`;
		let message = {language: 'ko'};
		console.log('setLanguageKo', topic, message);
		await this.mqttPublish(topic, message);
		await this.getLanguageState();
	},

	async setLanguageEn() {
		LikuUiStore.controlUpdateLikuData();
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/language`;
		let message = {language: 'en'};
		console.log('setLanguageEn', topic, message);
		await this.mqttPublish(topic, message);
		await this.getLanguageState();
	},

	async getLanguageState() {
		setTimeout(async () => {
			let likuSerial = await getCookie('uuid');
			let topic = `liku/${likuSerial}/language/get`;
			let message = {language: 'data'};
			console.log('setLanguageEn', topic, message);
			this.mqttPublish(topic, message);
		}, 1000);
	},

	async setConversation() {
		LikuUiStore.controlUpdateLikuData();
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/conversation/mode`;
		let message = { conversation : true };
		console.log('setConcersation', topic, message);
		this.mqttPublish(topic, message);
	},

	async setConversationOff() {
		LikuUiStore.controlUpdateLikuData();
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/conversation/mode`;
		let message = { conversation : false };
		console.log('setConcersation', topic, message);
		this.mqttPublish(topic, message);
	},

	async getConversation() {
		let likuSerial = await getCookie('uuid');
		let topic = `liku/${likuSerial}/conversation/mode/get`;
		let message = {language: 'data'};
		console.log('getConversation', topic, message);
		this.mqttPublish(topic, message);
	},
});

export {Mqtt};
