import React, {useEffect} from 'react'
// import { Empty, GameControlImage, VscCircleIcon, WiFiControlBox, WiFiControlButton, WiFiControlButtonText, WiFiControlDataInfo } from '../../style/home/HomeWiFiAndControllPresenter';
import wifiOn from '../../../resource/img/wifi.png';
import wifi_off from '../../../resource/img/wifi_off.png';
import controller from '../../../resource/img/controller.png';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import HomeLikuWifiModalPresenter from '../modal/connect/HomeLikuWiFiListModalPresenter';
import { requestPermission } from '../../../service/RequestPermission';
import SpinnerModal from '../modal/SpinnerModal';
import { WifiContentWrap, Close, WiFiControlButton, WiFiControlButtonText, WiFiLangBox, WiFiControlDataInfo, WiFiControlImage, WiFiControlData, WifiDataInfoView, WifiControlContent, WifiContronDataWrap, VscCircleIcon, WifiBtnWrap, CloseBtnWrap, CloseBtnContent, WifiOffText  } from '../../style/home/HomeWifiLangPresenter';
import LikuLangPresneter from '../../page/LikuLangPresneter';
import LikuFollwingPresenter from './LikuFollwingPresenter';

const HomeWifiLangPresenter = observer(({ button, data, icon, history, buttonFunction }) => {
  const { ModalUiStore, LikuUiStore } = RootStore();
  const handleWifi = async () => {
    await requestPermission({ type: "WiFi_getList" });
    await ModalUiStore.setOpen('wifiListModal');
    await ModalUiStore.setOpen('spinModal');
    setTimeout(() => {
      ModalUiStore.setOpen('spinModal');
    }, 3000);
  }

  useEffect(() => {
    console.log('data.state', data.state);
  }, [data]);

  return (
    <>
      <WiFiLangBox>
        <WifiContentWrap>
          <CloseBtnWrap>
            <CloseBtnContent>
             <Close />
              삭제
            </CloseBtnContent>
          </CloseBtnWrap>
          <WifiControlContent>
            <WiFiControlImage
              src={button === 'WiFi 변경 >' &&
                  data !== undefined &&
                  (data.state === 'COMPLETED' || data.state === 'CONNECTED')
                    ? wifiOn
                    : wifi_off} />
            <WifiContronDataWrap>
              <WiFiControlData>
                {icon === 'wifi' ? (
                  data !== undefined &&
                  (data.state === 'COMPLETED' || data.state === 'CONNECTED') ? data.id : '') : data} 
              </WiFiControlData>
              <WifiDataInfoView>
                <VscCircleIcon
                  $connect={
                    button === 'WiFi 변경 >' &&
                    data !== undefined &&
                    data !== 'undefined' &&
                    (data.state === 'COMPLETED' || data.state === 'CONNECTED')
                }
                  /> 
                <WiFiControlDataInfo>
                  {data !== undefined &&
                  data !== 'undefined' &&
                  (data.state === 'COMPLETED' || data.state === 'CONNECTED')
                    ? '연결되었습니다'
                    : '연결되지 않았습니다'}
                </WiFiControlDataInfo>
              </WifiDataInfoView>
            </WifiContronDataWrap>
          </WifiControlContent>
          <WifiBtnWrap>
            <WiFiControlButton 
              onClick={() => {
                icon === 'wifi' ? handleWifi() : history.push('/control')
              }}>
              <WiFiControlButtonText>{button}</WiFiControlButtonText>
            </WiFiControlButton>
          </WifiBtnWrap>
        </WifiContentWrap>
        {(LikuUiStore.thisLiku.wifi.state !== 'SCANNING') && (
          <>
            <LikuLangPresneter />
          </>
        )}
        {(LikuUiStore.thisLiku.wifi.state !== 'SCANNING') && (
          <>
            <LikuFollwingPresenter />
          </>
        )}
        {(LikuUiStore.thisLiku.wifi.state == 'SCANNING') && (
          <div>
            <WifiOffText>WiFi와 연결되면 부가 기능을 사용하실 수 있습니다.</WifiOffText>
          </div>
        )}
      </WiFiLangBox>
      <HomeLikuWifiModalPresenter
          open={ModalUiStore.open.wifiListModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
        />
      <SpinnerModal
          open={ModalUiStore.open.spinModal}
          setOpen={ModalUiStore.setOpen.bind(ModalUiStore)}
      />
    </>
  );
});

export default HomeWifiLangPresenter
